/********** Template CSS **********/
:root {
  --primary: #4761FF;
  --secondary: #555555;
  --light: #F1F3FA;
  --dark: #1C2035;
  --green: #688400;
}



body {
  font-family: 'Poppins', sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Poppins', sans-serif;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280,0,0,0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  filter: invert(1);
}

.logo-header {
  width: 310px;

  z-index: 99999;
  top: 0px;
}

.fixed-top {
  background-color: rgba(255, 255, 255, .9) !important;
}



.navbar-light .navbar-toggler {
  color: rgb(255 255 255 / 0%);
  border-color: rgb(255 255 255 / 0%);
}

.fw-cont {
  font-weight: 300 !important;
  background: #ffffffd1;
  padding: 29px;
}

.navbar.scrolled {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: rgba(255, 255, 255, .9) !important;
}


.navbar.scrolled .logo-header {
  display: none;
}


.navbar.scrolled.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55) !important;
}



.navbar .scrolled .navbar-light .navbar-nav .nav-link {
  margin-right: 40px;
  padding: 17px 20px 0px 10px !important;
  font-size: 16px;
  font-weight: 400;
  outline: none;

}

.navbar .navbar-nav .nav-link {
  margin-right: 40px;
  padding: 17px 20px 0px 10px;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  color: #fff;
}

.bg-menu-top {
  background: #29394f
}


.bg-menu-items {
  background: #e8e8e8;
}


.bg-menu {
  background: #dbdbdb00;
}

.text-justify {
  text-align: justify;
}

.text-green {
  color: #80C33F;
}

.text-blue {
  color: #103C55;
  font-size: 1.5em;
  font-weight: 700;
}

.icon-pagos {
  width: 60px;
  margin-right: 20px;
  float: left;
}


.title-sec:before {
  content: '';
  position: absolute;
  height: 8px;
  width: 22%;
  background-color: #fdb811;
  margin-left: 0px;
  margin-top: 96px;
}

.title-sec {
  font-weight: 700;
  font-size: 2.3rem;
  border-bottom: 1px solid #bfbfbf;
  line-height: 1.3;
}

/* .bg-map {

  background-size: cover;
  background-image: linear-gradient(rgba(241, 248, 246, 1), rgba(220, 233, 240, 0.5)),
      url("../img/webp/map-bg-2.png");

} */

.marg-banner {
  margin-top: -90px;
}

.float-area {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.floating-img {
  transform: translateY(-10%);
  animation: floater 6.5s infinite;
  transition: ease 0.5s;

}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  background: #103c55 !important;
}

.logo-fixed {
  display: none;
}

.shadow-sm .logo-fixed {
  display: block !important;
  width: 260px;
}



@keyframes floater {
  0% {
      transform: translateY(-10%);
      transition: ease 0.5s;
  }

  50% {
      transform: translateY(10%);
      transition: ease 0.5s;
  }
}



/* .img-infogra {} */


.des-banner {
  font-size: 20px;
  font-weight: 200;
  padding: 0px 100px;
}


.back-to-top {
  position: fixed;
  display: none;
  right: 30px;
  bottom: 30px;
  z-index: 99;
}

.fw-medium {
  font-weight: 500;
}


/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease-out, visibility 0s linear .5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity .5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}


/*** Button ***/
.btn {
  transition: .5s;
  font-weight: 500;
}

.btn-primary,
.btn-outline-primary:hover {
  color: var(--light);
}

.btn-secondary,
.btn-outline-secondary:hover {
  color: var(--dark);
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}


/*** Navbar ***/
.sticky-top {
  top: -100px;
  transition: .5s;
}

.navbar .navbar-nav .nav-link {
  margin-right: 40px;
  padding: 17px 20px 0px 10px !important;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  color: #fff;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
  color: #81c23f;
  font-weight: 500;
}

.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
}

@media (max-width: 991.98px) {
  .navbar .navbar-nav .nav-link {
      margin-right: 0;
      padding: 10px 0;
  }

  .navbar .navbar-nav {
      margin-top: 15px;
      border-top: 1px solid #EEEEEE;
  }
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
      display: block;
      border: none;
      margin-top: 0;
      top: 150%;
      opacity: 0;
      visibility: hidden;
      transition: .5s;
  }

  .navbar .nav-item:hover .dropdown-menu {
      top: 100%;
      visibility: visible;
      transition: .5s;
      opacity: 1;
  }
}


/*** Header ***/
.carousel-caption {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, .1);
  z-index: 1;
}

.carousel-control-prev,
.carousel-control-next {
  width: 6%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 3.5rem;
  background-color: #81c23f;
  border: 15px solid #2c2c2c;
}

@media (max-width: 768px) {
  #header-carousel .carousel-item {
      position: relative;
      min-height: 450px;
  }

  #header-carousel .carousel-item img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
  }
}

/* .page-header {
  background: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1)), url(../img/carousel-1.jpg) center center no-repeat;
  background-size: cover;
} */

.page-header .breadcrumb-item + .breadcrumb-item::before {
  color: var(--light);
}

.page-header .breadcrumb-item,
.page-header .breadcrumb-item a {
  font-size: 18px;
  color: var(--light);
}


/*** Features ***/
.feature-row {
  box-shadow: 0 0 45px rgba(0, 0, 0, .08);
}

.feature-item {
  background: white;
  margin-right: 20px;
  border-radius: 15px;

}

/*** About ***/
/* .about {
  background: linear-gradient(rgb(255 255 255 / 81%), rgb(255 255 255 / 0%)), url(../img/mapa.jpg) left center no-repeat;
  background-size: cover;
} */

.btn-play {
  position: relative;
  display: block;
  box-sizing: content-box;
  width: 36px;
  height: 46px;
  border-radius: 100%;
  border: none;
  outline: none !important;
  padding: 18px 20px 20px 28px;
  background: var(--primary);
  margin: 0px auto;
}

.btn-play:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 90px;
  height: 90px;
  background: var(--primary);
  border-radius: 100%;
  animation: pulse-border 1500ms ease-out infinite;
}

.btn-play:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 90px;
  height: 90px;
  background: var(--primary);
  border-radius: 100%;
  transition: all 200ms;
}

.btn-play span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  left: 3px;
  border-left: 30px solid #FFFFFF;
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
}

@keyframes pulse-border {
  0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      opacity: 1;
  }

  100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(2);
      opacity: 0;
  }
}

.modal-video .modal-dialog {
  position: relative;
  max-width: 800px;
  margin: 60px auto 0 auto;
}

.modal-video .modal-body {
  position: relative;
  padding: 0px;
}

.modal-video .close {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 0px;
  top: -30px;
  z-index: 999;
  font-size: 30px;
  font-weight: normal;
  color: #FFFFFF;
  background: #000000;
  opacity: 1;
}


/*** Service ***/
.service-item .service-text {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  text-align: center;
  background: #FFFFFF;
  box-shadow: 0 0 45px rgba(0, 0, 0, .06);
  transition: .5s;
  z-index: 2;
}

.service-item:hover .service-text {
  top: -1.5rem;
}

.service-item .service-text h5 {
  transition: .5s;
}

.service-item:hover .service-text h5 {
  color: var(--primary);
}

.service-item .service-btn {
  position: absolute;
  width: 80%;
  height: 3rem;
  left: 10%;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  box-shadow: 0 0 45px rgba(0, 0, 0, .07);
  transition: .5s;
  z-index: 1;
}

.service-item:hover .service-btn {
  bottom: -1.5rem;
}


/*** Project ***/
.project-item {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 45px rgba(0, 0, 0, .07);
}

.project-item .project-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .5);
  opacity: 0;
  padding-top: 60px;
  transition: .5s;
}

.project-item:hover .project-overlay {
  opacity: 1;
  padding-top: 0;
}

.project-carousel .owl-nav {
  position: absolute;
  top: -100px;
  right: 0;
  display: flex;
}

.project-carousel .owl-nav .owl-prev,
.project-carousel .owl-nav .owl-next {
  margin-left: 15px;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary);
  border-radius: 55px;
  box-shadow: 0 0 45px rgba(0, 0, 0, .15);
  font-size: 25px;
  transition: .5s;
}

.project-carousel .owl-nav .owl-prev:hover,
.project-carousel .owl-nav .owl-next:hover {
  background: var(--primary);
  color: #FFFFFF;
}

@media (max-width: 768px) {
  .project-carousel .owl-nav {
      top: -70px;
      right: auto;
      left: 50%;
      transform: translateX(-50%);
  }

  .project-carousel .owl-nav .owl-prev,
  .project-carousel .owl-nav .owl-next {
      margin: 0 7px;
      width: 45px;
      height: 45px;
      font-size: 20px;
  }

  .logo-fixed {
      display: flex;
      width: 50% !important;
      margin-left: 27%;
  }

  .bg-menu {
      background: #103c55;
  }

  .des-banner {
      font-size: 16px;
      font-weight: 200;
      padding: 0px 23px;
  }

  .btn-play {
      position: relative;
      display: block;
      box-sizing: content-box;
      width: 3px !important;
      height: 12px !important;
      border-radius: 100%;
      border: none;
      outline: none !important;
      padding: 14px !important;
      background: var(--primary);
      margin: 0px auto;
  }

  .btn-play:after {
      content: "";
      position: absolute;
      z-index: 1;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      display: block;
      width: 35px;
      height: 36px;
      background: var(--primary);
      border-radius: 100%;
      transition: all 200ms;
  }

  .btn-play span {
      display: block;
      position: relative;
      z-index: 3;
      height: 0;
      left: -1px;
      border-left: 10px solid #FFFFFF;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
  }

  .display-6 {
      font-size: calc(1rem + 1vw);
      font-weight: 700;
      line-height: 1.2;
  }

  .marquee {
      margin: 0rem 0;
      font-size: 5em;
      overflow: hidden;
      font-family: "Bebas Neue", Sans-serif;
      --tw: 81rem !important;
      --ad: 9.5s !important;
  }

  .title-sec {
      font-weight: 700;
      font-size: 1.48rem;
      border-bottom: 1px solid #bfbfbf;
  }

  .carousel-caption {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      background: rgba(0, 0, 0, .1);
      z-index: 1;

  }

  .title-sec:before {
      width: 50%;


  }
}



/*** Team ***/
.team-item {
  position: relative;
  text-align: center;
  box-shadow: 0 0 45px rgba(0, 0, 0, .07);
}

.team-item .team-social {
  position: absolute;
  padding: 0;
  top: 15px;
  left: 0;
  overflow: hidden;
}

.team-item .team-social li {
  list-style-type: none;
  margin-bottom: 10px;
  margin-left: -50px;
  opacity: 0;
  transition: .5s;
}

.team-item:hover .team-social li {
  margin-left: 15px;
  opacity: 1;
}

.team-item .team-social li .btn {
  background: #FFFFFF;
  color: var(--primary);
  border-radius: 40px;
  transition: .5s;
}

.team-item .team-social li .btn:hover {
  color: #FFFFFF;
  background: var(--primary);
}

.team-item .team-social li:nth-child(1) {
  transition-delay: .1s;
}

.team-item .team-social li:nth-child(2) {
  transition-delay: .2s;
}

.team-item .team-social li:nth-child(3) {
  transition-delay: .3s;
}

.team-item .team-social li:nth-child(4) {
  transition-delay: .4s;
}

.team-item .team-social li:nth-child(5) {
  transition-delay: .5s;
}


/*** Testimonial ***/
.testimonial-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, .07);
  transition: .5s;
}

.owl-item .testimonial-item,
.testimonial-item * {
  transition: .5s;
}

.owl-item.center .testimonial-item,
.testimonial-item:hover {
  background: var(--primary);
}

.owl-item.center .testimonial-item *,
.testimonial-item:hover * {
  color: #FFFFFF !important;
}

.testimonial-item img {
  width: 100px !important;
  height: 100px !important;
  border-radius: 100px;
}

.testimonial-carousel .owl-nav {
  position: absolute;
  top: -100px;
  right: 0;
  display: flex;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
  margin-left: 15px;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary);
  border-radius: 55px;
  box-shadow: 0 0 45px rgba(0, 0, 0, .15);
  font-size: 25px;
  transition: .5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
  background: var(--primary);
  color: #FFFFFF;
}

@media (max-width: 768px) {
  .testimonial-carousel .owl-nav {
      top: -70px;
      right: auto;
      left: 50%;
      transform: translateX(-50%);
  }

  .testimonial-carousel .owl-nav .owl-prev,
  .testimonial-carousel .owl-nav .owl-next {
      margin: 0 7px;
      width: 45px;
      height: 45px;
      font-size: 20px;
  }
}


/*** Footer ***/
.footer {
  color: #B0B9AE;
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  color: #B0B9AE;
  font-weight: normal;
  text-transform: capitalize;
  transition: .3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  color: var(--light);
  letter-spacing: 1px;
  box-shadow: none;
}

.copyright {
  color: #B0B9AE;
}

.copyright {
  background: #111111;
}

.copyright a:hover {
  color: var(--primary) !important;
}



.marquee {
  margin: 0rem 0;
  font-size: 6em;
  text-transform: uppercase;
  overflow: hidden;
  font-family: "Bebas Neue", Sans-serif;
  --tw: 97rem !important;
  --ad: 9.5s !important;
}

.marquee--long {
  font-size: 1rem;
}

.marquee span {
  display: inline-block;
  text-transform: uppercase;
  white-space: nowrap;
  color: #344f7608;
  width: var(--tw);
  text-shadow: var(--tw) 0 currentColor,
      calc(var(--tw) * 1) 0 currentColor,
      calc(var(--tw) * 2) 0 currentColor,
      calc(var(--tw) * 3) 0 currentColor;

  will-change: transform;
  animation: marquee var(--ad) linear infinite;
  animation-play-state: start;
}

.marquee:hover span {
  animation-play-state: running;
}

@keyframes marquee {
  0% {
      transform: translateX(0);
  }

  100% {
      transform: translateX(-100%);
  }
}

/*  
* on MacOs: System Preferences > 
*           Accessibility > 
*           Display > Reduce motion
*/

@media (prefers-reduced-motion: reduce) {
  .marquee span {
      animation: none;
      text-shadow: none;
      width: auto;
      display: block;
      line-height: 1;
      text-align: center;
      white-space: normal;
  }
}




:checked ~ .marquee span {
  animation-play-state: running;
}

label {
  font: .85rem Courier;
  margin-left: .5em
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: initial;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}


.bg-simulador {

  display: flex;
  margin-top: -80px;
  width: auto;
  min-height: 583px;
  background-image: linear-gradient(269deg, #0074D3 0%, #0174D2 31%, #0040A8 100%);
  text-align: center;
  overflow: hidden;
  padding-top: 70px;

}

.title-simulador {

  font-weight: 700;
  font-size: 2rem;
  border-bottom: 1px solid #bfbfbf;
  text-align: left;
  color: #fff;
  padding-bottom: 9px;

}

.title-simulador:after {
  content: '';
  position: absolute;
  height: 5px;
  width: 12%;
  background-color: #fdb811;
  margin-top: 49px;
  left: 27%;
  z-index: 99;
}

.descrip-simulador {
  color: #fff;
  text-align: left;
  font-size: 18px;
  font-weight: 200;
  line-height: 1.7;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 1;
  transform: scale(1) translateY(-2.5rem) translateX(0.15rem);
  color: #fff;
  font-size: 15px;
  margin-left: 0px;
  text-align: left;
}

.z-index-sec {
  z-index: 999 !important;
}

.title-detalle {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  border-bottom: 1px solid #c9c9c9;
  padding-bottom: 9px;
}

.list-simulador {
  color: #363636;
  font-weight: 200;
}

.list-simulador li span {
  color: #545252;
  font-weight: 400 !important;
}

.cont-valor-simulador {
  position: absolute;
  margin-left: 8vh;
  color: #fff;
  padding: 10px 20px;
  text-align: center;
  font-size: 14px;
  line-height: 1.6;
}

.bg-green-cont {
  background: #81c23f;
}


.text-efectivo {
  color: #ff5d1b;
}


.bg-efectivo {
  background: #ff5d1b;
}

.feature-item-palmaria {
  background: white;
  margin-right: 20px;
  border-radius: 15px;
  box-shadow: 1px 9px 30px 4px #a5a5a5;
}

@media (max-width: 990px) {

  .cont-valor-simulador {
      position: relative;
      margin-left: 0vh;
      color: #fff;
      padding: 10px 20px;
      text-align: center;
      font-size: 14px;
      line-height: 1.6;
  }

}



.cont-simulador-2 {
  background: #ffffff;
  border: 1px solid #d0d0d0;
  border-radius: 12px;
  margin-top: -156px;
}

.descrip-simulador-2 {
  color: #6b6565;
  font-size: 16px;
  font-weight: 200;
  line-height: 0;
  text-align: center;
}


.posit-bott {
  position: absolute;
  margin-left: 22%;
}


.title-simulador-2 {
  font-size: 25px;
  text-transform: uppercase;
  font-weight: 700;
  border-bottom: 1px solid #c9c9c9;
  padding-bottom: 9px;
}

.title-simulador {
  font-weight: 400;
  font-size: 2.2rem;
  border-bottom: 1px solid #bfbfbf;
  text-align: left;
  color: #fff;
  padding-bottom: 9px;
  letter-spacing: 3px;

  text-transform: uppercase;
}

@media (max-width: 768px) {

  .title-simulador {
      font-weight: 700;
      font-size: 1.4rem;

  }

  .descrip-simulador {
      color: #fff;
      text-align: justify;
      font-size: 15px;

  }

  .descrip-simulador-2 {
      color: #6b6565;
      font-size: 16px;
      font-weight: 200;
      text-align: center;
      line-height: 1.4;
  }

  .map-ersi {
      height: 430px;

  }

  .list-group-flush > .list-group-item {
    padding: 7px 1px;
  }
  
  .contact-form-box__left h5 {
    padding-top: 5px;
  }

}


.left-to-right {
  animation: rtLeftRight ease-in-out forwards infinite;
  animation-duration: 15s;
}

.rt-animated-img {
  position: absolute;
  height: auto;
  width: auto !important;
  right: 2%;
  top: 38vh;
}

@keyframes rtLeftRight {
  0% {
      transform: translateX(0)
  }

  50% {
      transform: translateX(80px)
  }

  100% {
      transform: translateX(0)
  }
}








/**--- style cards mobile----**/



.title-detalle-mobile {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 2.8;


}

.p-14 {
  font-size: 14px;
}

.price-mobile {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 2;
}


.progress {

  height: 5px !important;
}

.font-dates {
  font-size: 0.85rem !important;
}




/**--------------style simulador 2-----------------**/

@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap");


p {
  font-size: 14px;
}

.section-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.m-b-15 {
  margin-bottom: 15px;
}

.m-r-5 {
  margin-right: 5px;
}

.light-grey {
  color: #cbcbcb;
}

.contact-form {
  background: #fff;
  box-shadow: 0 23px 73px -17px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 993px) {
  .contact-form {
      flex-wrap: nowrap;
  }
}

.contact-form-box__left {
  background: #e7e7e7;
  /* background-image: url(http://127.0.0.1:49400/images/bg.jpg); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #676767;
  padding: 30px 30px 5px 30px;
  width: 100%;
  border-radius: 5px;
  position: relative;
}

@media (min-width: 993px) {
  .contact-form-box__left {
     width: 486px;
  transform: scale(1);
  }
}

.contact-form-box__left_footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px;
  text-align: center;
}

.contact-form-box__left_footer a {
  margin-right: 2px;
  padding: 8px;
  color: #4b65ee;
}

.contact-form-box__left_footer a:hover {
  color: #fff;
}

.contact-form-box__right {
  padding: 30px;
  width: 100%;
}

@media (min-width: 993px) {
  .contact-form-box__right {
      padding: 30px;
      width: calc(100% - 488px);
          padding-bottom: 20px;
      border-top: 10px solid #103c55;
  }
}

.btn-primary {
  background: #4b65ee;
  border-color: #4b65ee;
}

.btn-primary:hover {
  background: #006fbc;
  border-color: #006fbc;
}

/* Making the label break the flow */
.floating-label {
  position: absolute;
  top: 0;
  left: 0;
  user-select: none;
  z-index: 1500;
}

/* Hide the browser-specific focus styles */
.floating-label-field {
  color: rgba(44, 62, 80, .75);
  border-width: 0;
  z-index: 2000;
}

.floating-label-field:focus {
  outline: 0;
  box-shadow: 0;
}

.floating-label-field::placeholder {
  color: rgba(44, 62, 80, .5);
}

/* Make the label and field look identical on every browser */
.floating-label,
.floating-label-field {
  font: inherit;
  line-height: 1;
  display: block;
  width: 100%;
}

.floating-label-field,
.floating-label-wrap {
  position: relative;
}

/* Input Style #1 */
.floating-label-field--s1 {
  transition: border-color .25s ease-in-out;
  color: rgb(71 71 71);
  border-bottom: 1px solid rgb(75 101 238);
  background-color: transparent;
}

.floating-label-field--s1 + .floating-label {
  transition: transform .25s, opacity .25s ease-in-out;
  transform-origin: 0 0;
  opacity: .5;
}

.floating-label-field--s1:focus,
.floating-label-field--s1:not(:placeholder-shown) {
  border-color: rgb(75 101 238);
}

.floating-label-field--s1:focus + .floating-label,
.floating-label-field--s1:not(:placeholder-shown) + .floating-label {
  transform: translate(.25em, -30%) scale(.8);
  opacity: .25;
}

.floating-label-field--s1:valid:not(:placeholder-shown) {
  border-color: rgb(39 131 77 / 85%);
}

.floating-label-field--s1:invalid {
  border-color: rgb(199 9 36 / 85%);
}

/* Input Style #2 */
.floating-label-field--s2 {
  transition: background-color .25s ease-in-out;
  border: 2px solid #fff;
  border-radius: 5px;
  background-color: #fff;
}

.floating-label-field--s2 + .floating-label {
  visibility: hidden;
  transition: transform .25s, visibility .5s, opacity .5s ease-in-out;
  opacity: 0;
}

.floating-label-field--s2:focus + .floating-label,
.floating-label-field--s2:not(:placeholder-shown) + .floating-label {
  visibility: visible;
  transform: translateY(-80%);
  opacity: .5;
}

.floating-label-field--s2:valid:not(:placeholder-shown) {
  border-color: #adefc8;
  background-color: #adefc8;
}

.floating-label-field--s2:invalid {
  border-color: #ffe0e3;
  background-color: #ffe0e3;
}

.floating-label-field--s3 {
  padding: 1.5em;
  transition: border-color .25s ease-in-out;
  color: rgba(255, 255, 255, .75);
  border: 1px solid rgba(255, 255, 255, .1);
  border-radius: 5px;
  background-color: transparent;
}

.floating-label-field--s3 + .floating-label {
  position: absolute;
  top: .75em;
  left: .75em;
  display: inline-block;
  width: auto;
  margin: 0;
  padding: .75em;
  transition: transform .25s, opacity .25s, padding .25s ease-in-out;
  transform-origin: 0 0;
  color: rgba(255, 255, 255, .5);
}

.floating-label-field--s3:focus,
.floating-label-field--s3:not(:placeholder-shown) {
  border-color: rgba(255, 255, 255, .1);
}

.floating-label-field--s3:focus + .floating-label,
.floating-label-field--s3:not(:placeholder-shown) + .floating-label {
  z-index: 2500;
  padding: .75em;
  transform: translate(0, -2em) scale(.9);
  color: rgba(255, 255, 255, .25);
  background-color: #2c3e50;
}

/* Common Styles */
/* Identical inputs on all browsers */
.floating-label-field--s1:not(textarea),
.floating-label-field--s2:not(textarea),
.floating-label-field--s3:not(textarea) {
  max-height: 4em;
}

.floating-label-field--s1,
.floating-label-field--s1 + .floating-label,
.floating-label-field--s2,
.floating-label-field--s2 + .floating-label {
  padding: 1.5em 0em 0.5em 0em;
}

.floating-label-field--s1 + .floating-label,
.floating-label-field--s2 + .floating-label {
  z-index: 1500;
}

.floating-label-field--s1::placeholder,
.floating-label-field--s3::placeholder {
  color: transparent;
}


.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: #e7e7e7 !important;
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}

.list-group-flush > .list-group-item {
  font-size: 14px;
  padding: 1px;
}

.contact-form-box__left h5 {
  font-size: 16px;
  border-bottom: 1px solid #c9c9c9;
  padding-bottom: 3px;
}

.modal{
  z-index: 2000;
}

.btn-green {
  background-color: #688400;
  color: #ffffff
}

.btn-green:hover{
  background-color: #83a700;
  color: #ffffff
}

.btn-back {
  background-color: #e7e7e7;
  color: #676767;
}

.captch-style{
  -webkit-transform: scale(1);
  transform: scale(0.9);
}

@media (max-width: 575px) {
  .subtitle-form{
    display: none !important;
  }

  .list-group-flush > .list-group-item {
    padding: 7px 1px;
  }
  
  .contact-form-box__left h5 {
    padding-top: 5px;
  }
}